import React from 'react'
import LeaderBoard from './leaderboard/form/leaderBoard';
const players = [
  { name: "Alice", points: 98 },
  { name: "Bob", points: 75 },
  { name: "Charlie", points: 120 },
  // ... other players
];
const PlayersData = () => {
  return (
      <>
          <LeaderBoard players={players}/>
      </>
  )
}

export default PlayersData