import React from 'react';
import { useNavigate } from "react-router-dom";
const AffiliateProgramInfo = () => {
    const navigate = useNavigate();
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold text-center mb-6">
          Join Our Affiliate Program
        </h1>
        <div className="text-center mb-8">
          <button
            type="button"
            onClick={() => navigate("/")}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-2lg px-5 py-2.5 focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Join Now
          </button>
        </div>
        <p className="text-lg text-gray-700 mb-4">
          Become a part of our vibrant community and help spread the word about
          our revolutionary JEE preparation platform. By filling out a simple
          form, you will receive a unique group invitation link. This isn't just
          any link; it comes with a hidden opportunity to earn through our
          affiliate program whenever a JEE aspirant you refer purchases one of
          our products.
        </p>
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4 mx-auto">
            Commission Structure
          </h2>
          <ul className="list-disc list-inside space-y-2 mx-auto">
            <li>Earn an 8% commission for less than 20 students enrolled.</li>
            <li>Earn a 12% commission for 20-40 students enrolled.</li>
            <li>Earn a 16% commission for 40-100 students enrolled.</li>
            <li>Earn a 20% commission for 100+ students enrolled.</li>
          </ul>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Rewards and Incentives</h2>
          <p className="text-lg text-gray-700 mb-2">
            Achieving any of the last three commission milestones will not only
            boost your earnings but also unlock exciting rewards. We're talking
            exclusive certificates, trendy goodies, and premium merchandise to
            celebrate your success and hard work.
          </p>
          <p className="text-lg text-gray-700">
            Dive into a journey of empowerment and contribution. Let's shape the
            future of JEE aspirants together.
          </p>
        </div>
        <div className="text-center mb-8">
          <button
            type="button"
            onClick={() => navigate("/")}
            className="text-white mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-2lg px-5 py-2.5 focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Join Now
          </button>
        </div>
      </div>
    );
};

export default AffiliateProgramInfo;
