import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import FormBuilder from './components/leaderboard/form/form'
import PlayersData from './components/playersData';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='*' errorElement={true}/>
          <Route path="/" element={<FormBuilder />} />
          <Route path='/leaderBoard' element={<PlayersData/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App