import React, { useState } from "react";

export default function FormBuilder() {
  const query = new URLSearchParams(window.location.search);
  const uniqueCode = query.get("code");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    coaching: "",
    city: "",
    pincode: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    let errors = {};
    // Name validation
    if (!formData.name.trim()) errors.name = "Name is required.";
    // Email validation
    if (!formData.email.trim()) {
      errors.email = "Email is required.";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Email is invalid.";
    }
    // Contact validation
    if (!formData.contact.trim()) errors.contact = "Contact is required.";
    // Additional validations for other fields can be added here
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const canSubmit =
    Object.values(formData).every((value) => value?.trim() !== "") &&
    Object.keys(formErrors).length === 0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) return;

    setIsSubmitting(true);
    const endpoint = "https://iitian-buddy-ecdb196d3a83.herokuapp.com/form/";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          uniqueCode: uniqueCode,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        window.location.href =
          "https://chat.whatsapp.com/KbMskE1ONpyDzSaGTbwx09";
      } else {
        alert("Form submission failed:", data.message);
      }
    } catch (error) {
      alert("There was an error submitting the form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto px-4">
      <div className="w-full flex justify-center mt-5">
        <h1 className="text-2xl font-bold text-gray-800">
          Fill Details to become IITian...
        </h1>
      </div>
      <form className="w-full max-w-lg mx-auto mt-5" onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => (
          <div key={key} className="w-full px-3 mt-4 mb-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor={`grid-${key}`}
            >
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id={`grid-${key}`}
              name={key}
              type={key === "email" ? "email" : "text"}
              placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
              value={formData[key]}
              onChange={handleChange}
            />
            {formErrors[key] && (
              <p className="text-red-500 text-xs italic">{formErrors[key]}</p>
            )}
          </div>
        ))}
        <div className="flex justify-center mt-4">
          <button
            className={`shadow ${
              canSubmit && !isSubmitting
                ? "bg-blue-500 hover:bg-blue-700"
                : "bg-blue-300"
            } text-white font-bold py-2 px-12 rounded`}
            type="submit"
            disabled={!canSubmit || isSubmitting}
          >
            Join Group
          </button>
        </div>
      </form>
    </div>
  );
}
