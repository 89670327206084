import React, { useEffect, useState } from "react";
import { FaTrophy } from "react-icons/fa";
import AffiliateProgramInfo from "../affiliateRule";

const Leaderboard = () => {
  const [players, setPlayers] = useState([]);
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [referralId, setReferralId] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log(players);
  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch(
          "https://iitian-buddy-ecdb196d3a83.herokuapp.com/affiliate/leaderboard",
          {
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPlayers(data); // Update players state with fetched data
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  // const handlePhoneNumberSubmit = (e) => {
  //   e.preventDefault();
  //   // Logic to get referral ID using the phone number
  //   // For now, we'll just set a dummy referral ID
  //   setReferralId("REF123456");
  // };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <div className="mt-10">
      <div className="max-w-4xl mx-auto p-4 border-2 border-gray-300 rounded-lg shadow-lg mb-6">
        <h1 className="text-4xl font-bold text-center text-gray-700 mb-4">
          Leaderboard
        </h1>
        <div className="overflow-auto max-h-96">
          <table className="min-w-full">
            <thead>
              <tr className="text-left text-gray-600">
                <th className="px-4 py-2">Rank</th>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Points</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">
              {players?.leaderboard?.map((player, index) => ( player.userInfo &&
                <tr key={index} className="bg-white">
                  <td className="px-4 py-2 font-bold text-gray-800">
                    {index === 0 ? (
                      <FaTrophy className="inline text-yellow-400" />
                    ) : index === 1 ? (
                      <FaTrophy className="inline text-gray-400" />
                    ) : index === 2 ? (
                      <FaTrophy className="inline text-orange-400" />
                    ) : (
                      index + 1
                    )}
                  </td>
                  <td className="px-4 py-2 font-medium text-gray-800">
                    {player?.userInfo?.name}
                  </td>
                  <td className="px-4 py-2 font-medium text-gray-800">
                    {player?.count}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* <div className="max-w-4xl mx-auto px-4">
        <form
          onSubmit={handlePhoneNumberSubmit}
          className="flex flex-col items-center"
        >
          <label
            htmlFor="phoneNumber"
            className="mb-2 text-lg font-medium text-gray-700"
          >
            Enter your phone number for a referral ID:
          </label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            required
            pattern="[0-9]{10}"
            placeholder="Enter your 10-digit phone number"
            className="form-input mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-lg leading-tight focus:outline-none focus:shadow-outline"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <button
            type="submit"
            className="mt-4 px-6 py-2 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-700 transition-colors"
          >
            Get Referral ID
          </button>
        </form> 
      

        {referralId && (
          <div className="mt-4 p-4 bg-green-100 border border-green-300 text-green-800 rounded-md">
            Your referral ID is: {referralId}
          </div>
        )}
        </div>*/}
      <AffiliateProgramInfo />
    </div>
  );
};

export default Leaderboard;
